import Link from "next/link";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import NavBar from "../../../Element/NavBar";

const HeaderLeft = ({ mainMenu }) => {
  const {overlay, TopMenuToggle} = useSelector((state) => state.ModalReducer);
  const sortedMenuNodes = [...mainMenu?.menu_nodes]?.sort((a, b) => a.position - b.position);
  const [check, setCheck] = useState(false);

  useEffect(() => {
    if (TopMenuToggle) {
        document.body.style.overflow = 'hidden'; 
    } else {
        document.body.style.overflow = 'auto'; 
    }
  }, [TopMenuToggle]);

  return (
    <div className="header__left__wrapper">
      <div className="header__logo">
       <Link href='/'>
          <svg width="148" height="50" viewBox="0 0 148 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4402_6192)">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M88.44 5.24C91.26 4.89 93.44 5.4 94.72 7.16C96.39 9.46 95.76 11.18 94.83 13.6C93.28 17.6 91.73 21.73 90.27 25.79C89.54 27.83 86.35 36.5 85.66 37.93C84.02 41.31 80.63 44.2 75.87 44.74C72.76 45.09 70.79 44.53 69.5 42.74C67.87 40.48 68.49 38.76 69.42 36.39C71.72 30.51 74.06 24.04 76.28 18.06C78.39 12.36 79.89 6.31 88.44 5.24ZM108.66 14.75C110.06 8.09 105 1.1 97.67 0.14C95.83 -0.0999996 93.2 0.0400004 91.24 0.0400004C85.02 0.0400004 80.82 -0.0999994 74.9 3.01C72.01 4.53 69.22 6.64 67.52 8.54C61.95 14.72 57.37 26.71 55.6 35.25C54.23 41.89 59.21 48.82 66.5 49.84C67.83 50.03 77.79 50.01 79.34 49.88C87.84 49.16 95.1 44.52 99.59 37.79C103.28 32.27 107.21 21.63 108.66 14.74V14.75Z" fill="#B72024"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M106.84 49.98C107.93 49.98 108.05 49.59 108.86 48.35C116.14 37.5 123.42 26.65 130.71 15.8C130.88 17.84 131.92 29.41 131.79 30.48C130.4 31.05 128.34 30.89 124.92 32.58C122.82 33.61 121.14 34.95 119.74 36.23C118.4 37.46 117.03 39.49 115.85 41.29C115.13 42.4 112.75 45.73 112.33 46.58C113.73 46.17 115.73 45.21 117.14 44.62C120.61 43.15 129.07 39.56 132.54 40.06C132.99 41.44 133.08 46.18 133.24 48.17C133.41 50.22 133.15 49.99 134.2 49.99C138.62 49.99 143.03 49.99 147.45 49.99C146.17 33.35 144.89 16.71 143.61 0.0799995C141.24 0.0799995 138.87 0.0699995 136.5 0.0599995C131.99 0.0599995 131.94 1.67 129.62 4.95L99.49 50H106.84V49.98Z" fill="#B72024"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M33.31 0.0400009L18.36 0.0700009L0 49.98H14.87L21.27 32.8C21.66 31.76 21.64 31.1 22.6 30.36L58.61 2.71L62 0.0400009H51.85C51.15 0.0400009 50.55 0.240001 49.99 0.670001L26.51 18.6L33.31 0.0400009Z" fill="#B72024"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M26.89 30.06C27.01 30.63 34.13 44.26 34.64 45.26C36.37 48.69 36.76 49.93 41.51 49.93C45.22 49.93 49.22 50.07 52.89 49.91L45.56 35.62C43.83 32.21 41.7 28.31 40.09 24.99C39.78 24.35 39.49 23.84 39.17 23.22L38.26 21.55C38.26 21.55 38.22 21.5 38.2 21.48L26.91 30.06H26.89Z" fill="#B72024"/>
            </g>
            <defs>
              <clipPath id="clip0_4402_6192">
                <rect width="147.45" height="50" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </Link>
      </div>
      <div className="navbar__wrapper">
        <NavBar mainMenu={mainMenu} />
      </div>
    </div>
  )
};

export default HeaderLeft;